<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
 
        <el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
                @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
                <div>
                  <el-button @click="showEdit=true"  class="ele-btn-icon addbtn" size="small" v-if="permission.includes('sys:privacy:add')">添加隐私政策</el-button>
                </div>
                <div class="d-flex">
                  <el-form-item label="显示版：" label-width="70px" class="w-200">
                    <el-select v-model="table.where.side_type" placeholder="请选择" clearable class="ele-fluid">
                     <el-option  label="全部" value="0"/>
                      <!-- <el-option  label="全平台" value="111"/> -->
                      <!-- <el-option  label="咱县打车用户版" value="1"/>
                      <el-option label="咱县打车司机版" value="2"/>
                      <el-option label="咱县打车跑腿版" value="3"/>
                      <el-option label="咱县打车商家版" value="4"/> -->
                      <el-option label="可蚁点用户版" value="7"/>
                      <el-option label="可蚁点司机版" value="8"/>
                      <!-- <el-option label="可蚁点跑腿版" value="3"/>
                      <el-option label="可蚁点商家版" value="4"/> -->
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                      <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">查询</el-button>
                      <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                  </el-form-item>
                </div>
        </el-form>

      <!--       数据表格-->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left" show-overflow-tooltip/>
          <el-table-column prop="side_type" label="显示版"  show-overflow-tooltip min-width="250">
            <template slot-scope="scope">
              {{ scope.row.side_type == 7 ?'可蚁点用户版':(scope.row.side_type == 8 ?'可蚁点司机版':
						(scope.row.side_type == 3 ?'可蚁点跑腿版':(scope.row.side_type == 4 ?'可蚁点商家版':(scope.row.side_type == 0 ?'全平台':'暂无对应'
						))))}}
            </template>
          </el-table-column>
          <el-table-column label="更新时间"  show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:privacy:edit')">编辑</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑隐私协议通知':'添加隐私协议通知'" :visible.sync="showEdit"
               @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
      <el-card  shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">
          <el-form-item label="显示版：" prop="side_type">
            <el-select v-model="editForm.side_type" calss="selectStyle" :disabled="editForm.id?true:false" clearable>
              <el-option v-for="option in accordList" :value="option.id" :key="option.id" :label="option.name"></el-option>
            </el-select>
          </el-form-item>
             <el-form-item label="图文内容:">
             <tinymce-editor v-model="editForm.content" :init="editContent"/>
            </el-form-item>
        <!-- 富文本编辑器 -->
      </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";
export default {
  name: "SysNotice",
  components: {TinymceEditor},
  data() {
    return {
      table: {url: '/notice/privacy_list', where: {}},  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {source:1,status:1,is_top:2},  // 表单数据
      accordList:[
        // {id:0,name:'全平台'},
        {id:7,name:'可蚁点用户版'},
        {id:8,name:'可蚁点司机版'},
        // {id:3,name:'可蚁点跑腿版'},
        // {id:4,name:'可蚁点商家版'},
      ],
      sideType:'',
      provArr:[],
      cityArr:[],
      districtArr:[],
      editRules: {  // 表单验证规则
        side_type: [
            {required: true, message: '请选择显示版', trigger: 'change'}
        ],
        pid: [
            {required: true, message: '请选择省', trigger: 'change'}
        ],
      },
      // 自定义文件上传(这里使用把选择的文件转成blob演示)
      file_picker_callback: (callback, value, meta) => {
        let input = document.createElement('input');
        input.setAttribute('type', 'file');
        // 设定文件可选类型
        if (meta.filetype === 'image') {
          input.setAttribute('accept', 'image/*');
        } else if (meta.filetype === 'media') {
          input.setAttribute('accept', 'video/*');
        }
        input.onchange = () => {
          let file = input.files[0];
          let reader = new FileReader();
          reader.onload = (e) => {
            let blob = new Blob([e.target.result], {type: file.type});
            callback(URL.createObjectURL(blob));
          };
          reader.readAsArrayBuffer(file);
        }
        input.click();
      }
    }
  },
  computed: {
    ...mapGetters(["permission"]),

    editContent() {
      return {
        menubar: false,
        // file_picker_callback: this.file_picker_callback,
        skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
        content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
      };
    }
  },
    created(){
     this.$http.get('/common/province_list').then(res=>{
          var data=JSON.parse(res.data)
          this.provArr=data
        })
  },
  mounted() {
  },
  methods: {
    /**
     *选择省
     **/
    handleChangeProv(e){
      /** 获取被选省份的pid**/
      var pid=''
      this.provArr.forEach(function(item){
        if(item.pid==e){
          pid=item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list',{pid:pid}).then(res=>{
        var data=JSON.parse(res.data)
        this.cityArr=data
        /** 选择省份清空市县**/
        this.editForm.cid=''
        this.editForm.aid=''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e){
			if(e){
      /** 获取被选市的cid**/
      var cid=''
      this.cityArr.forEach(function(item){
        if(item.cid==e){
          cid=item.cid
        }
      })
      /** 根据被选市的cid获取市下面的县**/
      this.$http.post('/common/area_list',{cid:cid}).then(res=>{
        var data=JSON.parse(res.data)
        this.districtArr=data
        /** 选择省份清空县**/
        this.editForm.aid=''
      })
			}else{
				this.editForm.pid = ''
				this.editForm.aid = ''
			}
    },

    /* 显示编辑 */
    // edit(row) {
    //   this.editForm = Object.assign({}, row);
    //   //console.log(row)
    //   this.sideType=this.editForm.side_type
    //   if(this.editForm.side_type==1){
    //     this.editForm.side_type='咱县打车用户版'
    //   }else if(this.editForm.side_type==2){
    //     this.editForm.side_type='咱县打车司机版'
    //   }else if(this.editForm.side_type==3){
    //     this.editForm.side_type='咱县打车跑腿版'
    //   }else if(this.editForm.side_type==4){
    //     this.editForm.side_type='咱县打车商家版'
    //   }else if(this.editForm.side_type==5){
    //     this.editForm.side_type='可蚁点用户版'
    //   }else if(this.editForm.side_type==6){
    //     this.editForm.side_type='可蚁点司机版'
    //   }else if(this.editForm.side_type==7){
    //     this.editForm.side_type='可蚁点跑腿版'
    //   }else if(this.editForm.side_type==8){
    //     this.editForm.side_type='可蚁点商家版'
    //   }
    //   this.showEdit = true;
    // },
    edit(row) {
      // this.editForm = Object.assign({}, row);
      this.$http.post('/notice/info', {id:row.id}).then(res => {
        if (res.data.code === 0) {
          //console.log(res.data.data);
          this.editForm = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
      this.showEdit = true;
    },
    /* 保存编辑 */
    save() {
      // if(this.sideType!=0){
      //   this.editForm.side_type=this.sideType
      // }
      if(this.sideType){
        this.editForm.side_type=this.sideType
      }
      this.editForm.notice_type=4
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({lock: true});
          this.$http.post('/notice/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 删除 */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的通知吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/notice/delete', {id: ids}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        const loading = this.$loading({lock: true});
        this.$http.post('/notice/delete', {id:row.id}).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      }
    },

  }
}
</script>

<style scoped>
.ele-block >>> .el-upload, .ele-block >>> .el-upload-dragger {
  width: 100%;
}
</style>